<template>
  <div class="Business_i">
    <div class="tab_Top">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect">
          <el-menu-item index="1">专利申请</el-menu-item>
          <el-menu-item index="2">商标注册</el-menu-item>
        </el-menu>
        <div class="Men_1">
          说明：为售后服务老师量身打造的板块，更好提升服务效率，提高服务品质
        </div>
      </div>

    <Trademark v-if="isShow == 2"> </Trademark>   <!-- 商标注册 -->
    <div v-if="isShow == 1">
      <div class="tab_Bor"> 
        <div class="T1 bord">
          <div style="flex:1">
            <span style="float:left">专利类型：</span>
          <div class="T_sele" >
              <el-select v-model="search.patent_type" placeholder="请选择专利类型">
                <el-option v-for="(item, i) in BBBBB_list.patent_type" :key="i" :label="item" :value="i"></el-option>
              </el-select>
          </div>
          </div>
          <div style="flex:1">
            <span style="float:left">服务商类型：</span>
          <div class="T_sele" >
            <el-select v-model="search.business_type" placeholder="请选择服务商类型">
              <el-option v-for="item in BBBBB_content.sales_business_type" :key="item.value" :label="item.content" :value="item.value"></el-option>
            </el-select>
          </div>
          </div>
          <div style="flex:1">
            <span style="float:left">站点：</span>
          <div class="T_sele" >
              <el-select v-model="search.site_id" placeholder="请选择站点">
                <el-option v-for="item in SiteList" :key="item.site_id" :label="item.name" :value="item.site_id"></el-option>
              </el-select>
          </div>
          </div>
          <div style="flex:1">
            <span style="float:left">业务员：</span>
          <div class="T_sele" >
            <el-select v-model="search.business_id" placeholder="请选择业务员">
              <el-option v-for="item in BusinessList" :key="item.id" :label="item.username"  :value="item.id"></el-option>
            </el-select>
          </div>
          </div>
          <div style="flex:0.8"></div> 
        </div>
        <div class="T1">
          <div style="flex:1.7">
          <el-tooltip class="item" effect="dark" content="创建的时间" placement="top">
            <span style="float:left;margin-right:15px">创建时间<i class="el-icon-question"></i> :</span>
            </el-tooltip>
            <div  style="float:left;" class="Topo Tuyiiu T_sele">
              <el-date-picker v-model="search.time.min" type="date" placeholder="选择月份" :clearable="false"></el-date-picker>
              <span style="margin: 0 15px 0 15px;display:inline">至</span>
              <el-date-picker v-model="search.time.max" type="date" placeholder="选择月份" :clearable="false"></el-date-picker>
            </div>
          </div>
          <div style="flex:0.8">
            <div class="Actit" @click="BtnSearch()"  style="margin-left:15px;cursor: pointer;">查询</div>
            <div class="Actit dsdsdsdaaa"  @click="BtnSearch(false)">重置</div>
          </div> 
          <div style="flex:1"></div>
          <div style="flex:1"></div>
        </div>
      </div>

      <!-- 列表 -->
      <div class="Tables">
        <div class="T_sele dasdas">
          <el-select v-model="type" placeholder="请选择线索" @change="Chang_ses">
            <el-option v-for="item in MMD" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </div>
         <div class="Tab_T" @click="showAdd()" v-if="TextMoMo('workbench:sales:primary:add')"><i class="el-icon-plus"></i>人员登记</div>
        <div class="Tab_T Tab_y" style="border: 1px solid #FF4D4F;color:#FF4D4F"
           v-if="TextMoMo('workbench:sales:primary:del')"   @click="Add_Del()"><i class="el-icon-delete"></i>批量删除</div>
          <div class="el_seso"><i class="el-icon-search" @click="BtnSearch()"></i></div>
          <div class="dsds">
            <el-input v-model="search.keyword" placeholder="请输入姓名" @keyup.enter.native="BtnSearch()"></el-input>
          </div>
          <div v-if="TextMoMo('workbench:sales:primary')">
            <el-table
              :data="tableData"
              class="table"
              ref="multipleTable"
              header-cell-class-name="table-header"
              @selection-change="Selection">
              <el-table-column type="selection" width="55" align="center"></el-table-column>
              <el-table-column prop="id" label="ID" width="55" align="center" ></el-table-column>
              <el-table-column align="center" prop="username" label="姓名"></el-table-column>
              <el-table-column align="center" prop="business.username" label="业务员"></el-table-column>
              <el-table-column align="center" prop="patent_type_text" label="专利类型"></el-table-column>
              <el-table-column align="center" prop="applicant" label="申请人"></el-table-column>
              <el-table-column align="center" prop="entry_time" label="创建日期"></el-table-column>
              <el-table-column align="center" prop="mobile" label="手机号码"></el-table-column>
              <el-table-column align="center" prop="monopoly" label="专利权人"></el-table-column>
              <el-table-column align="center" prop="site.name" label="站点"></el-table-column>
              <el-table-column align="center" label="操作" width="200">
                <template #default="scope" >
                    <div class="el_butto">
                      <el-button
                      type="text" 
                      @click="HandleTransfer(scope.row)"
                      >转移</el-button>
                    </div>
                    <div class="el_butto">
                      <el-button
                      type="text" v-if="TextMoMo('workbench:sales:primary:edit')" 
                      @click="HandleEdit(scope.row)"
                      >编辑</el-button>
                    </div>
                  <div class="el_butto rere" >
                      <el-button
                      type="text"
                      class="red" v-if="TextMoMo('workbench:sales:primary:del')" 
                      @click="HandleDel(scope.row)"
                      >删除</el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div class="block">
              <el-pagination
                background
                @current-change="handleCurrentChange"
                :current-page="currentPage1"
                :page-size="page.pageSize"
                layout="total, prev, pager, next, jumper"
                :total="total">
              </el-pagination>
            </div>
          </div>
      </div>
    </div>
    
    <!-- 创建线索 -->
    <div ref="rightPanel" v-if="ruleFormRef" class="rightPanel-container show">
      <div class="rightPanel-background" />
      <div style="width: 822px;position: fixed;top: 0;right: 0;z-index:999;" >
        <el-scrollbar style="height:100%">
          <div class="rightPanel" style="width:822px;max-width: 822px;height: 833px;padding-bottom: 120px;">
            <div class="Rig_1">{{titleMath}}<i class="el-icon-close" @click="Close" style="float:right;padding:20px 50px;cursor: pointer;"></i></div>
            <div style="margin:0 0 18px 24px " v-if="chakan111 == 1">
              <el-menu
                :default-active="acndex"
                class="el-menu-demo"
                mode="horizontal"
                @select="handleSelect2">
                <el-menu-item index="1">人员信息</el-menu-item>
                <el-menu-item index="2">进程记录</el-menu-item>
                <el-menu-item index="3">办理进度</el-menu-item>
                <el-menu-item index="4">附件资料</el-menu-item>
              </el-menu> 
            </div>
            <div class="Rig_A" v-if="isRow == 1">
              <div class="Rioo_c" style="padding-bottom:0">
                <span>订单验证：</span>
                <el-input style="width: 100%;" v-model.trim="order" placeholder="请输入本次服务对象对应的订单号"></el-input>
                <span style="color:#1890FF;font-size:14px;float:right;margin-top:14px;cursor: pointer;" @click="Query()">点击验证</span>
              </div>
              <div v-if="D_yan2 == 1" style="color: #262626;font-size: 14px;margin-top:45px">验证成功，系统有此订单，可查看详情</div>
              <div v-if="D_yan2 == 2" style="color: #262626;font-size: 14px;margin-top:45px">验证失败，系统查无订单，请重新输入</div>
              <div class="Rioo_c" style="border:none" v-if="D_yan2 == 1">
                <span style="margin-bottom:10px;display:inline-block">订单验证：</span>
                <el-input style="width: 100%;" v-model="Order.order_no" placeholder="请输入本次服务对象对应的订单号" disabled></el-input>
                <span style="color:#1890FF;font-size:14px;float:right;margin-top:15px;cursor: pointer;" @click="ChaKan()">查看详情</span>
              </div>
              <div class="xinxi_B">一、订单基本信息</div>
              <div class="Diidd_Yi">
                <div class="Yiiiuyt">
                  <div class="Rig_D Ruuia">
                    <div class="Rig_Ddd" >
                      <span style="color:#FF4D4F">* </span>买家姓名</div>
                    <div class="Rtty "> 
                      <el-input v-model="Order.goodsone.user_nickname" disabled placeholder="请验证订单号" class="El_ii"></el-input>
                    </div>
                  </div>
                </div>
                <div class="Yiiiuyt">
                  <div class="Rig_D Ruuia3">
                    <div class="Rig_Ddd" >买家联系方式</div>
                    <div class="Rtty ">
                      <el-input v-model="Order.goodsone.user_mobile" disabled class="El_ii" placeholder="请验证订单号"></el-input>
                    </div>
                  </div>
                </div>
                <div class="Yiiiuyt">
                  <div class="Rig_D Ruuia3">
                    <div class="Rig_Ddd" >订单状态</div>
                    <div class="Rtty ">
                      <el-input v-model="Order.order_status_text" disabled class="El_ii" placeholder="请验证订单号"></el-input>
                    </div>
                  </div>
                </div>
                <div class="Yiiiuyt">
                  <div class="Rig_D Ruuia">
                    <div class="Rig_Ddd" > 服务对象</div>
                    <div class="Rtty ">
                      <el-input v-model="val2" placeholder="请验证订单号" disabled class="El_ii"></el-input>
                    </div>
                  </div>
                </div>
                <div class="Yiiiuyt">
                  <div class="Rig_D Ruuia3">
                    <div class="Rig_Ddd" >购买项目</div>
                    <div class="Rtty ">
                      <el-input v-model="Order.goodsone.goods_name" disabled class="El_ii" placeholder="请验证订单号"></el-input>
                    </div>
                  </div>
                </div>
                <div class="Yiiiuyt">
                  <div class="Rig_D Ruuia3">
                    <div class="Rig_Ddd" >购买数量</div>
                    <div class="Rtty ">
                      <el-input v-model="Order.goodsone.total_num" disabled class="El_ii" placeholder="请验证订单号"></el-input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="Rig_A" v-if="isRow == 2">
              <div class="xinxi_B">一、基本信息</div>
              <div class="Diidd_Yi">
                <div class="yiiiuyt">
                    <div class="Rig_D Ruuia">
                        <div class="Rig_Ddd" > 
                          <span style="color:#FF4D4F">* </span>联系方式</div>
                        <div class="Rtty ">
                          <el-input v-model="Row.mobile" disabled placeholder="请填写联系方式" class="El_ii"></el-input>
                        </div>
                    </div>
                </div>
                <div class="yiiiuyt">
                    <div class="Rig_D Ruuia3">
                        <div class="Rig_Ddd" ><span style="color:#FF4D4F">* </span> 客户姓名</div>
                        <div class="Rig_Rrr" @click="hetong()">查看合同</div>
                        <div class="Rtty "> 
                          <el-input v-model="Row.username" disabled class="El_ii" placeholder="请填写申客户姓名"></el-input>
                        </div>
                    </div>
                </div>
                <div class="yiiiuyt">
                    <div class="Rig_D Ruuia3">
                        <div class="Rig_Ddd" ><span style="color:#FF4D4F">* </span> 身份证号码</div>
                        <div class="Rtty ">
                          <el-input v-model="Row.id_card" class="El_ii" placeholder="请填写申报人身份证号码"></el-input>
                        </div>
                    </div>
                </div>
                <div class="yiiiuyt">
                    <div class="Rig_D Ruuia3">
                        <div class="Rig_Ddd" style="width:240px;margin-bottom: 1px;"><span style="color:#FF4D4F">* </span> 工作单位</div>
                        <div class="Rtty ">
                          <el-input v-model="Row.company" class="El_ii" placeholder="请填写工作单位"></el-input>
                        </div>
                    </div>
                </div>
                <div class="yiiiuyt">
                    <div class="Rig_D Ruuia3">
                        <div class="Rig_Ddd" ><span style="color:#FF4D4F">* </span> 所属站点</div>
                        <el-select v-model="Row.site_id" placeholder="请选择所属站点" disabled>
                          <el-option v-for="item in SiteList" :key="item.site_id" :label="item.name" :value="item.site_id"></el-option>
                        </el-select>
                    </div>
                </div>
                <div class="yiiiuyt">
                    <div class="Rig_D Ruuia">
                        <div class="Rig_Ddd" > 
                          <span style="color:#FF4D4F">* </span>所属业务人员</div>
                        <el-select v-model="Row.business_id" placeholder="请选择所属业务人员" disabled>
                          <el-option v-for="item in BusinessList" :key="item.id" :label="item.username"  :value="item.id"></el-option>
                        </el-select>
                    </div>
                </div>
              </div>
              <div class="xinxi_B">二、申请信息</div>
              <div class="Diidd_Yi">
                <div class="yiiiuyt">
                  <div class="Rig_D Ruuia3">
                      <div class="Rig_Ddd"><span style="color:#FF4D4F">* </span> 申请专利类型</div>
                      <el-select v-model="Row.patent_type" placeholder="请选择申请专利类型">
                        <el-option v-for="(item, i) in BBBBB_list.patent_type" :key="i" :label="item" :value="i"></el-option>
                      </el-select>
                  </div>
                </div>
                <div class="yiiiuyt">
                  <div class="Rig_D Ruuia3">
                      <div class="Rig_Ddd"><span style="color:#FF4D4F">* </span> 发明人（自然人）</div>
                      <el-input type="text" v-model="Row.inventor" placeholder="填写注册资本"></el-input>
                  </div>
                </div>
                <div class="yiiiuyt">
                    <div class="Rig_D Ruuia3">
                        <div class="Rig_Ddd"><span style="color:#FF4D4F">* </span> 专利权人</div>
                        <div class="Rtty">
                            <el-input type="text" v-model="Row.monopoly" placeholder="填写专利权人" ></el-input>           
                        </div>
                    </div>
                </div>
                <div class="yiiiuyt">
                    <div class="Rig_D Ruuia3">
                        <div class="Rig_Ddd"><span style="color:#FF4D4F">* </span> 申请人</div>
                        <el-input type="text"  v-model="Row.applicant" placeholder="填写申请人" ></el-input>
                    </div>
                </div>
                <div class="yiiiuyt">
                    <div class="Rig_D Ruuia3">
                        <div class="Rig_Ddd"><span style="color:#FF4D4F">* </span> 技术交底情况</div>
                        <el-input type="text"  v-model="Row.technique" placeholder="填写技术交底情况" ></el-input>
                    </div>
                </div>
              </div>
              <div class="xinxi_B">三、服务信息</div>
              <div class="Diidd_Yi">
                <div class="yiiiuyt">
                    <div class="Rig_D Ruuia3">
                        <div class="Rig_Ddd" style="margin-bottom: 10px;"><span style="color:#FF4D4F">* </span> 服务商类型</div>
                        <div class="Rig_Rrr" @click="Clue(1)">内容管理</div>
                        <div class="Rtty">
                          <el-select v-model="Row.business_type" placeholder="请选择服务商类型">
                            <el-option v-for="item in BBBBB_content.sales_business_type" :key="item.value" :label="item.content" :value="item.value"></el-option>
                          </el-select>
                        </div>
                    </div>
                </div>
                <div class="yiiiuyt">
                    <div class="Rig_D Ruuia3">
                        <div class="Rig_Ddd"><span style="color:#FF4D4F">* </span> 报单日期</div>
                        <div class="Rtty">
                          <el-date-picker v-model="Row.entry_time" type="data" placeholder="选择报单日期" :clearable="false" ></el-date-picker>        
                        </div>
                    </div>
                </div>
              </div>
              <div class="xinxi_B">四、备注信息</div>
              <div class="Rig_D"  style="width:100%">
                <div class="Rtty">
                  <el-input type="textarea" style="width:96%" v-model="Row.remark" placeholder="可以将客户其他重要的信息备注到这里" ></el-input>           
                </div>
              </div>
              <div class="BCSZ" style="margin-right:24px;" @click="BtnAdd(1)" v-if="resetForm == 'xinjian'">创建人员</div>
              <div class="BCSZ" style="margin-right:24px;" @click="BtnAdd(2)" v-if="resetForm == 'baocun'">保存设置</div>
              <div class="BCSZ" style="background:#fff;color:#595959;border: 1px solid #D9D9D9;margin-right:15px" @click="Close">取消</div>
            </div>
            <div class="Rig_A" v-if="isRow == 3">
              <div class="Rig_D Tigtgg R_texxt" >
                <div class="TRRE">
                  <div style="margin:8px 0 9px 0;font-size: 14px;color: #333333;">全部进程信息</div>
                    <div style="margin:20px 24px 0 1px;" v-if="FlowList.length != 0">
                      <el-scrollbar height="100%">
                        <div id="time-line">
                          <div class="item" v-for="item in FlowList" :key="item.index">
                            <div class="ytyt">
                              <div class="item-index">{{ item.createtime }}</div>
                              <div class="item-content">{{ item.info }}</div>
                              <img class="Rtt_im22g" v-for="item in item.imgs_url" :key="item" :label="item" :value="item" :src="item"   />
                            </div>
                          </div>
                        </div>
                      </el-scrollbar>
                    </div>
                  </div>
                <div class="Rig_D" style="position: relative;width:100%">
                    <div class="Rig_Ddd" style="margin-bottom:9px;margin-top:30px"> <span style="color:#FF4D4F">*</span> 文字记录</div>
                    <div class="Rtty">
                        <el-input type="textarea" style="width:97%" v-model="Info_desc" 
                        placeholder="请将每次沟通、维护、跟单、咨询等相关信息记录在这里，为了提高效率
                        ① 选择常用语更新记录
                        ② 手动输入文字信息
                        ③ 将��要的凭证、历史沟通、其他社交媒体截图等在下方上传保存"></el-input>           
                    </div>     
                  <div class="Rtty Riuo fdgfg" style="clear:both;"> 
                    <div style="margin:4px 23px 0 0;float:right">
                        <el-checkbox v-model="valueA_input1" label="同步给客户"></el-checkbox>
                    </div>
                  </div>
                </div>  
                <div>
                  <div class="Rig_Ddd" style="margin:28px 0 9px 0">图片线索（选填）</div>
                  <div class="Rtty TUUu" style="clear:both"> 
                      <div style="margin-top:20px">
                        <el-upload
                          :action="upload"
                          :headers="heads"
                          list-type="picture-card"
                          :on-success="UP_Success2"
                          :on-remove="HAndemove2"
                          :on-preview="handlePictureCardPreview">
                          <i class="el-icon-plus"></i>
                          <div class="el_asff">上传图片</div>
                        </el-upload>
                      </div>
                  </div>
                </div>
                <div class="Rtty">
                    <div class="BCSZ" style="margin-bottom: 0px;margin-right:15px" @click="updateBtn()">更新进程</div>
                    <div class="BCSZ" style="margin-bottom: 0px;background:#fff;color:#595959;border: 1px solid #D9D9D9;">取  消</div>
                </div>
              </div>
            </div>
            <div class="Rig_A" v-if="isRow == 4">
              <div style="overflow:hidden" class="Rioiio">
                <div class="Righh_q"  v-for="item in ProressList" :key="item.index">
                  <div class="Rdffdf">{{item.title}}</div>
                  <div>
                    <el-progress :text-inside="true" :stroke-width="26" :percentage="item.progress"></el-progress>
                  </div>
                  <div class="Riddfsa" :class="idw == item.id ? 'Ridd_activ' : ''" >{{item.content}}</div>
                  <div class="Riddfsa2" style="margin-right:13px" v-if="idw != item.id" @click="Zhankai(item.id)">展开</div>
                  <div class="Riddfsa2" style="margin-right:13px" v-if="idw == item.id" @click="Zhankai(item.id)">收回</div>
                  <div class="Riddfsa2" @click="Lishi(item)">历时进度</div>
                </div>
              </div>
              <div class="BCSZ" style="margin-right:30px" @click="editVisible2 = true">编辑</div>
              <div class="BCSZ" style="background:#fff;color:#595959;border: 1px solid #D9D9D9;margin-right:15px" @click="show1 = false">取消</div>
            </div>
            <div class="Rig_A" style="padding-left: 0;" v-if="isRow == 5">
              <div class="Ri_bord">
                <el-table
                  :data="FileList_TEXT"
                  border
                  style="width: 93%; margin: 20px 0 0 28px">
                  <el-table-column prop="createtime" align="center" label="上传时间"> </el-table-column>
                  <el-table-column prop="name" align="center" label="文件名称"> </el-table-column>
                  <el-table-column prop="filesize" align="center" width="100" label="文件大小"> </el-table-column>
                  <el-table-column prop="staff.realname" align="center" width="100" label="上传者"> </el-table-column>
                  <el-table-column label="操作" align="center">
                    <template #default="scope">
                      <div class="mui">
                        <div class="mmim "> 
                          <a style="color:#1890FF" :href="scope.row.full_filepath" :download="scope.row.name + '.' + scope.row.format">下载</a>
                        </div>
                        <div class="mmim " @click="handleEdit2(1 , scope.row)">删除</div>
                      </div>  
                    </template>
                  </el-table-column>
                </el-table>
                <div class="Ri_tj" @click="tianjia(1)" style="width:93%;margin-left:28px">添加</div>
              </div>
            </div>
          </div>
        </el-scrollbar>
      </div>
    </div>
    <!-- 创建线索 -->


    <!-- 转移 -->
    <el-dialog title="转移订单" v-model="editVisible8" width="30%" class="">
        <el-form ref="form" label-width="100px" >
          <div class="DDGGD">
            <el-form-item label="服务对象：">
              <el-input v-model="Row2.TA_name222" disabled></el-input>
            </el-form-item>
            <el-form-item label="专利类型：">
               <el-input v-model="Row2.TA_name111" disabled></el-input>
            </el-form-item>
            <el-form-item label="员工：">
              <div class="Rtty Riiiu">
                <el-select v-model="Row2.staff_id" placeholder="请选择人员">
                  <el-option v-for="item in StaFFList" :key="item.id" :label="item.realname" :value="item.id"></el-option>
                </el-select>
              </div>
            </el-form-item>
          </div>
        </el-form>
        <template #footer>
          <span class="dialog-footer" style="margin-bottom:28px">
            <el-button @click="editVisible8 = false" style="margin-right:15px">取 消</el-button>
            <el-button type="primary" @click="saveEdit2(3)">确 认</el-button>
          </span>
        </template>
    </el-dialog>
    <!-- 转移 -->


    <!-- 业务进度编辑 -->
    <el-dialog title="业务进度编辑" v-model="editVisible2" width="36%" class="">
        <div class="DDGGD" v-for="item in ProressList_W" :key="item">
          <el-input class="Dssd_inopu" style="display: block;width:200px!important" v-model="item.title" placeholder="填写标题"></el-input>
          <el-input class="Dssd_inopu" style="width:200px" type="number" v-model="item.progress" placeholder="填写进度比"></el-input>
          <el-input class="Dssd_inopu2" style="width: 61.4%;" v-model="item.content" placeholder="填写本阶段的办理进度情况，如：目前只剩下业绩未交我方"></el-input>
          <div style="border-bottom: 1px dashed #ccc;margin: 8px;"></div>
        </div>
        <div @click="Pro_bj" style="color:#1890FF;cursor: pointer;">
          创建新的阶段
        </div>
      <template #footer>
        <span class="dialog-footer" style="margin-bottom:28px">
          <el-button @click="editVisible2 = false" style="margin-right:15px">取 消</el-button>
          <el-button type="primary" @click="saveEdit2(2)">确 认</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 业务进度编辑 -->

    <!-- 历时进度信息 -->
    <el-dialog title="历时进度信息" v-model="editVisible4" width="32%" class="">
        <div class="TRRE" style="margin:0px 24px 0 1px;height:300px" v-if="FlowList2.length != 0">
            <el-scrollbar height="100%">
              <div id="time-line">
                <div class="item" v-for="item in FlowList2" :key="item.index">
                  <div class="ytyt">
                    <div class="item-index">{{ item.createtime }}</div>
                    <div class="item-content">{{ item.content }}</div>
                  </div>
                </div>
              </div>
            </el-scrollbar>
        </div>
    </el-dialog>
    <!-- 历时进度信息 --> 

    <!-- 导入资料 -->
    <el-dialog title="导入资料" v-model="editVisible7" width="31%">
        <el-form ref="form" label-width="120px">
          <div class="DDGGD">
          <el-form-item label="选择导入文件：" style="margin-bottom:5px">
            <div class="el-form-item__content">
              <el-upload
                  accept=".xls,.xlsx"
                  :action="upload"
                  :headers="heads"
                  :multiple="false"
                  :file-list="fileList2"
                  :on-success="UP_Success4"
                  :on-remove="fileRemove">
                  <el-button size="small" type="primary">选择文件</el-button>
                  <div class="el-upload__tip">一次只能上传一个xls/xlsx文件，且不超过10M</div>
              </el-upload>
          </div>
          </el-form-item>
          </div>
        </el-form>
        <template #footer>
          <span class="dialog-footer" style="margin-bottom:28px">
            <el-button @click="editVisible7 = false" style="margin-right:15px">取 消</el-button>
            <el-button v-if="isInfo == 1" type="primary" @click="File_Sure2(1)">确 认</el-button>
            <el-button v-if="isInfo == 3" type="primary" @click="File_Sure2(3)">确 认</el-button>
          </span>
        </template>
    </el-dialog>
    <!-- 导入资料 -->

    <!--全部线索类型管理-->
    <el-dialog title="内容管理" v-model="dialogVisib" width="36%"> 
      <div class="Tabbl">
        <el-table
        :data="ClueData"
        class="table"
        header-cell-class-name="table-header"
        @selection-change="handleSelectionChange">
        <el-table-column label="线索类型" width="240" align="center" >
          <template #default="scope" >
              <div class="El_pop dasdasd">
                <el-input type="text" :disabled = "scope.row.type == 'default' ? true : false"  v-model="scope.row.content" placeholder="输入内容"></el-input>
              </div>
          </template>
        </el-table-column>
        <el-table-column label="设置" width="180" align="center">
            <template #default="scope">
            <div v-if="scope.row.type == 'default'">预设</div>
            <div v-if="scope.row.type == 'custom'">自定义</div>
            </template>
        </el-table-column>
        <el-table-column label="是否启用" width="100">
          <template #default="scope" >
            <div class="el_butto rere dsdtrt">
                <el-switch
                  :disabled="scope.row.type == 'default' ? true : false "
                  v-model="scope.row.status"
                  active-value = 'normal'
                  inactive-value = 'hidden'
                  active-color="#1890FF"
                  @change="changeSwitch($event,scope.row,scope.$index)"
                  inactive-color="#dcdfe6">
                </el-switch>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100" align="center" >
          <template #default="scope" >
            <div class="el_butto rere dsdtrt">
                <i class="el-icon-delete" @click="haeDete(scope.row)"></i>
            </div>
          </template>
        </el-table-column>
      </el-table>
      </div>
      <div class="Y_ids" @click="CreateAdd(AFF)"><i class="el-icon-plus"></i>创建内容</div>
      <div class="Dityo">
        <div class="dialog-footer" style="margin-bottom:20px">
          <el-button @click="dialogVisib = false">取 消</el-button>
          <el-button type="primary" @click="determine()">确 定</el-button>
        </div>
      </div>  
    </el-dialog>
    <!--全部线索类型管理-->

    <!-- 查看合同 -->
    <el-dialog title="查看合同" v-model="editVisible3" width="47.3%">
      <el-scrollbar>
        <div class="rightPanel" style="overflow-y: auto ;">
          <div class=" Riyy">
            <div class="Ri_tit">基本信息</div>
            <div class="Riidid" style="display: flex">
              <div class="Yiiiuyt">
                <div class="Rig_D" style="margin-right:15px">
                  <div class="Rig_Ddd" style="margin-bottom:4px"><span>*</span> 姓名</div>
                  <div class="Rtty" >
                  <el-input v-model="Customer.username" placeholder="请填写客户姓名" disabled class="El_ii"></el-input>
                  </div>
                </div>
              </div>
              <div class="Yiiiuyt">
                <div class="Rig_D" style="margin-right:15px">
                  <div class="Rig_Ddd" style="margin-bottom:4px"><span>*</span> 联系方式</div>
                  <div class="Rtty">
                  <el-input v-model="Customer.mobile" placeholder="请填写客户手机号码" disabled class="El_ii"></el-input>
                  </div>
                </div>
              </div>
              <div class="Yiiiuyt">
                <div class="Rig_D">
                  <div class="Rig_Ddd" style="margin-bottom:4px">客户昵称</div>
                  <div class="Rtty">
                    <el-input v-model="Customer.username" placeholder="请填写客户使用的昵称" disabled class="El_ii"></el-input>
                  </div>
                </div>
              </div>
            </div>
            <div class="Ri_tit" >基本信息</div>
            <div class="Ri_cen" style="width:98%">
                <div>
                  <span>所有的订单</span>
                  <span>{{Tongji.total}}</span>
                </div>
                <div>
                  <span>未完成订单</span>
                  <span>{{Tongji.unfinish}}个</span>
                </div>
                <div>
                  <span>已退费的订单</span>
                  <span>{{Tongji.refund}}个</span>
                </div>
            </div>
            <div class="Ri_bord" style="width: 99%;">
              <el-table
                :data="OrderList"
                border
                align="center"
                style="width: 100%; margin-top: 20px">
                <el-table-column prop="createtime" label="购买时间" align="center" width="120"> </el-table-column>
                <el-table-column  prop="order_no" align="center" width="157" label="订单号"> </el-table-column>
                <el-table-column prop="goodsone.goods_name" align="center" width="120" label="购买项目"> </el-table-column>
                <el-table-column prop="goodsone.business_username"  width="102" align="center" label="服务商"> </el-table-column>
                <el-table-column prop="pay_type_text" align="center" width="90" label="支付方式"> </el-table-column>   
                <el-table-column prop="pay_price" align="center" width="90" label="支付金额"> </el-table-column>  
                <el-table-column prop="order_status_text" align="center" width="80" label="订单状态"> </el-table-column>   
                <el-table-column label="操作" align="center"  width="70">
                  <template #default="scope">
                    <div class="mmim" @click="handleEdit(scope.row)">查看</div>
                  </template>
                </el-table-column>
              </el-table>
              <div class="Ri_tj" style="width:100%">添加</div>
            </div>
            <div class="Ri_tit" style="margin-top:18px">附加资料</div>
            <div class="Ri_bord" style="width: 99%;">
              <el-table :data="FileList" border style="width: 100%; margin-top: 20px">
                <el-table-column prop="createtime" align="center" label="上传时间"> </el-table-column>
                <el-table-column prop="name" align="center" label="文件名称"> </el-table-column>
                <el-table-column prop="filesize" align="center" width="100" label="文件大小"> </el-table-column>
                <el-table-column prop="staff.realname" align="center" width="100" label="上传者"> </el-table-column>
                <el-table-column label="操作" align="center">
                  <template #default="scope">
                    <div class="mui">
                      <div class="mmim "> 
                        <a style="color:#1890FF" :href="scope.row.full_filepath" :download="scope.row.name + '.' + scope.row.format">下载</a>
                      </div>
                      <div class="mmim " @click="handleEdit2(3, scope.row)">删除</div>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <div class="Ri_tj" @click="tianjia(3)" style="width:100%">添加</div>
            </div>
          </div>
        </div>
      </el-scrollbar>
      <template #footer>
        <span class="dialog-footer" style="margin-bottom:28px">
          <el-button @click="editVisible2 = false" style="margin-right:15px">取 消</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 查看合同 -->
  </div>
</template>

<script>
import axios from 'axios'
import  TextMoth  from "../../components/utils.vue";
import Trademark from './components/Trademark.vue'
export default {
  components:{
    Trademark
  },
  data() {
    return{
      upload:'https://rest-api.duxin365.com/admin/ajax/upload',
      upload2:'https://rest-api.duxin365.com/admin/ajax/upload',
      heads:{token:''},
      acndex:'1',
      activeIndex:'1',
      val2:'买家本人', 
      type:'self',
      total:'',
      titleMath:'',
      resetForm:'',
      editId:'', 
      isRow:'',
      order:'',
      D_yan2:'',
      Customer_id:'',
      multipleSelection_id:'',
      chakan111:'',
      dataId:'',
      Info_desc:'',
      idw:0,
      File2:null,
      File2_name:'',
      dialoge:'',
      fieldKey:'', 
      content:'',
      isInfo:'',
      isShow: 2,
      BBBBB_list:{},
      BBBBB_content:{}, 
      salestype:[],
      tableData:[],
      BusinessList:[],
      multipleSelection:[],
      SiteList:[],
      StaFFList:[],
      ProressList_W: [],
      FlowList: [],
      FlowList2:[],  
      ProressList:[],
      FileList_TEXT:[],
      Row_Eurl:[],
      Row_Eurl2:[],
      fileList2:[],
      Tongji:[],
      OrderList:[],
      FileList:[],
      MMD:[{id:'self',name:'我的订单'},{id:'common',name:'全部订单'}],
      ruleFormRef:false,
      editVisible8:false,
      editVisible2:false,
      editVisible4:false,
      editVisible7:false,
      dialogVisib:false,
      editVisible3:false,
      tableData:[],
      page: {   // 分页
        currentPage: 1,
        pageSize: 10,
      },
      search: {  //搜索参数
        patent_type : "", //专利类型
        business_type : "", //服务商类型
        business_id : "", //业务员ID（服务商）
        site_id : "", //站点ID
        keyword :  "" ,
        time:{
          min:'',
          max:'',
        }
      },
      Order:{
        goodsone:{},
        order_status_text:'',
      },
      Row2:{
        TA_name222:'',
        TA_name111:'',
        staff_id:'',
      },
      Row:{
        username : "", //申报人姓名
        mobile : "", //申报人联系方式
        id_card : "", //申报人身份证号
        company : "", //工作单位
        company_type : "", //注册公司类型
        site_id : "", //所属站点
        business_id : "", //服务商ID
        order_id : "", //订单ID
        customer_id : "", //客户ID
        patent_type : "", //专利类型
        inventor : "", //发明人
        monopoly : "", //专利权人
        applicant : "", //申请人
        technique : "", //技术交底
        business_type : "", //服务商类型
        entry_time : "", //报单日期
        remark : "" //备注信息
      },
      Customer:{
        username:'',
        mobile:'',
        username:'',
      }
    }
  },
  mounted(){
    var that = this
    let token = window.localStorage.getItem("accessToken")
    that.heads.token = token
    that.staff()
    that.loadTable()
  },
  methods:{
    loadTable() {
      var that = this
      axios({
        method: 'post',
        url: '/admin/workbench/sales/patentList',
        data: {
          page: that.page.currentPage,
          limit: that.page.pageSize,
          type: that.type,
          filter: that.search,
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total = res.data.data.total
        that.$forceUpdate()
      })
    },
    //创建
    showAdd(){
      var that = this
      that.ruleFormRef = true;
      that.titleMath = '创建人员'
      that.isRow = 1
      that.chakan111 = 2
      that.Row = {}
    },
    // 获取编辑详情 
    HandleEdit(data) {                       
      var that = this
      that.staff()
      that.ruleFormRef = true;
      that.resetForm = 'baocun';
      that.titleMath = '编辑人员'
      that.editId = data.id
      that.isRow = 2
      that.chakan111 = 1
      that.Row.customer_id = data.customer_id
      axios.get('/admin/workbench/sales/patentInfo', {  
        params: {
          id: data.id
        }
      }).then(function (res) {
        that.Row = res.data.data.row
      })
    },
    BtnAdd(e){
      var that = this
      let y1 = ''
      that.Row.customer_id = that.Customer_id
      if(that.Row.entry_time != '') {
        y1 = that.formatDate(that.Row.entry_time);
        that.Row.entry_time = y1
      }
      if( e == 1 ){
        axios({
          method: 'post',
          url: '/admin/workbench/sales/addPatent',
          data: {
            row: that.Row
          },
        }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.ruleFormRef = false
            that.loadTable();
          }
        }) 
      }else if( e == 2 ){
        axios({
          method: 'PUT',
          url: '/admin/workbench/sales/editPatent',
          data: {
            id:that.editId,
            row: that.Row
          },
        }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.ruleFormRef = false
            that.loadTable();
          }
        }) 
      }
    },
    //删除
    HandleDel(data) {
      var that = this
      var arr = []
      arr.push(data.id)
      that.$confirm("确定要删除吗？", "提示", {
        type: "primary"
      }).then(() => {
        axios({
          method: 'DELETE',
          url: '/admin/workbench/sales/delPatent',
          data: {
            ids: arr
          }
        }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.loadTable();
          }
        })
      })
    },
    Add_Del() {     //批量删除          
      var that = this
      if (that.multipleSelection_id == null) {   
        that.$message.warning('请勾选信息');
      } else {
        that.$confirm("确定要删除吗？", "提示", {
          type: "primary"
        }).then(() => {
          axios({
            method: 'delete',
            url: '/admin/workbench/sales/delPatent',
            data: {
              ids: that.multipleSelection_id
            },
          }).then(function (res) {
              if (res.data.code == 0) {
                that.$message.warning(res.data.msg);
              } else {
                that.$message.success(res.data.msg);
                that.loadTable()
              }
          }) 
        })
      } 
    },
    //转移
    HandleTransfer(data){
      var that = this
      that.editVisible8 = true
      that.dataId = data.id
      that.yuangong()
      that.Row2.TA_name222 = data.username
      that.Row2.TA_name111 = data.patent_type_text
    },
    saveEdit2(e) {             
      var that = this
      console.log(e);
      if( e == 2 ){
        axios({
          method: 'post',
          url: '/admin/workbench/sales_info/editProgress', 
          data: {
            type:'patent',
            source_id:that.editId,
            content:that.ProressList_W
          },
        }).then(function (res) {
            console.log(res.data.data);
            if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
            } else{
                that.editVisible2 = false
                that.ProressList = res.data.data.proressList
             }
        })
      }else if( e == 3 ){
        axios({
          method: 'post',
          url: '/admin/workbench/sales/transferPatent', 
          data: {
            id: that.dataId,
            row:{
              staff_id : that.Row2.staff_id
            }
          },
        }).then(function (res) {
            if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
            } else{
                that.editVisible8 = false
                that.loadTable()
             }
        })
      }
    },
    //搜索
    BtnSearch(e) {
      var that = this
      that.page.currentPage = 1;
      if( e == false){
        that.search = {
          patent_type : "", //专利类型
          business_type : "", //服务商类型
          business_id : "", //业务员ID（服务商）
          site_id : "", //站点ID
          keyword :  "" ,
          time:{
            min:'',
            max:'',
          }
        }
      }
      let y1 = ''
      let y2 = ' '
      that.Row.customer_id = that.Customer_id
      if(that.search.time.min != '') {
        y1 = that.formatDate(that.search.time.min);
        that.search.time.min = y1
      }
      if(that.search.time.max != '') {
        y2 = that.formatDate(that.search.time.max);
        that.search.time.max = y2
      }
      that.loadTable()
      axios({
        method: 'post',
        url: '/admin/workbench/sales/patentList',
        data: {
          page: that.page.currentPage,
          limit: that.page.pageSize,
          type: that.type,
          filter: {
            patent_type :   that.search.patent_type,
            business_type :   that.search.business_type,
            business_id :   that.search.business_id,
            site_id :   that.search.site_id,
            keyword :   that.search.keyword,
            time:{
              min : y1, 
              max : y2, 
            }
          },
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total = res.data.data.total
      })
    },
    //查询订单
    Query(){
      var that = this
      if( that.order != '' ){
        axios.get('/admin/order/order/getOrderbyOrderNo',{   // 设置选项 
          params:{
            order_no: that.order
          }
        }).then(function (res) {
        if (res.data.code == 0) {
          alert('请求失败');  
        } else{ 
          console.log(res.data.data.order)
            if (res.data.data.order == null) {
              that.D_yan2  = 2
              that.Order = {
                goodsone:{},
                order_status_text:'',
              }
            } else {
              that.D_yan2  = 1
              that.Order = res.data.data.order
              that.Row.username = res.data.data.order.goodsone.user_nickname
              that.Row.mobile = res.data.data.order.goodsone.user_mobile
              that.Row.site_id = res.data.data.order.site_id
              that.Row.business_id = parseInt(res.data.data.order.business_id)
              that.Row.order_id = res.data.data.order.id
              that.Customer_id = res.data.data.order.customer_id
            }
        }
      }) 
      } 
    },
    ChaKan(){
      this.isRow = 2
      this.resetForm = 'xinjian'
      this.staff()
    },
    Pro_bj() {
      var that = this
      var Pror = that.ProressList_W
      var arr = {
        id:0,
        title:'',
        progress:'',
        content:''
      }
      Pror.push(arr)
      that.ProressList_W = Pror
    },
    flowRead() {  //线索进程
      var that = this
      axios.get('/admin/customer/flow/read',{   
            params:{
              customer_id: that.Row.customer_id,
              type: "patent", 
              source_id: that.editId,  
            }
        }).then(function (res) {
          if (res.data.code == 0) {
            alert('请求失败');  
          } else { 
            that.FlowList = res.data.data.flow_list
          }
      }) 
    },
    progressList(){   //业务进度信息
      var that = this
      axios({                                              
        method: 'post',
        url: '/admin/workbench/sales_info/progressList', 
        data: {
          type:'patent',
          source_id: that.editId
        },
      }).then(function (res) {
        console.log(res.data.data)
        if (res.data.code == 0) {
          that.$message.warning(res.data.msg);
        }else {
          that.ProressList = res.data.data.proressList 
          that.ProressList_W = res.data.data.proressList.slice()   //复制一个数组 
        }
      })
    },
    updateBtn(){  //更新进程
      var that = this
      axios({
        method: 'post',
        url: '/admin/customer/flow/add', 
        data: {
          row:{
            customer_id: that.Row.customer_id, //客户ID，客户管理时必填
            info: that.Info_desc, //进程的信息
            type: "patent",
            source_id: that.editId, //当前类型所在列表ID
            images: that.Row_Eurl2
          }
        },
      }).then(function (res) {
        if(res.data.code == 0) {
          that.$message.warning(res.data.msg);
          that.Info_desc = ''
        } else {
          that.$message.success(res.data.msg);
          that.Info_desc = ''
          that.flowRead()
        }
      })
    },
    Zhankai(e) {         //办理进度  展开
      var that = this
      if (that.idw == e) {that.idw = 982} else {that.idw = e}
    },
    Lishi(e) {           //办理进度 历史信息
      var that = this
      that.editVisible4 = true
      that.FlowList2 = e.history
    },
    //附件
    handleEdit2(e,data) {     
      console.log(e , data);   
      var that = this
      if( e == 1 ){
        that.$confirm("确定要删除吗？", "提示", {
          type: "warning"
          }).then(() => {
          axios.delete('/admin/workbench/sales_info/delFile',{ 
            params:{
              id: data.id
            }
          }).then(function (res) {
            if (res.data.code == 0) {
                that.$message.warning(res.data.msg);
            } else{ 
              that.$message.success(res.data.msg);
              that.workbench()
            }
          })
        })
      } else if( e == 3 ){
        that.$confirm("确定要删除吗？", "提示", {
          type: "warning"
          }).then(() => {
          axios.delete('/admin/customer/contract/delFile',{ 
            params:{
              id: data.id
            }
          }).then(function (res) {
            if (res.data.code == 0) {
                that.$message.warning(res.data.msg);
            } else{ 
              that.$message.success(res.data.msg);
              that.xiangqing()
            }
          })
        })
      } 
    }, 
    File_Sure2(e) {          
      var that = this
      that.fileList2 = []
      if( e == 1 ){
        axios({                                              
          method: 'post',
          url: '/admin/workbench/sales_info/addFile', 
          data: {
            row:{
              type:'patent',
              source_id:that.editId,
              name:that.File2_name,
              filepath:that.File2
            }
          },
        }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          }else {
            that.editVisible7 = false
            that.workbench()
          }
        })
      }else if( e == 3 ){
        axios({                                              
          method: 'post',
          url: '/admin/customer/contract/addFile', 
          data: {
            row:{
              customer_id:that.Row.customer_id,
              name:that.File2_name,
              filepath:that.File2
            }
          },
        }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          }else {
            that.editVisible7 = false
            that.xiangqing()
          }
        })
      }
    },
    //新增编辑选项控制
    handleSelect2(key) {
      if (key == 1) {
        this.isRow = 2;
      } else if(key == 2){
        this.isRow = 3
        this.flowRead()
      } else if(key == 3){
        this.isRow = 4
        this.progressList()
      } else if(key == 4){
        this.isRow = 5
        this.workbench()
      }
    },
    hetong(){
      var that = this
      console.log(that.Row.customer_id );
      if(that.Row.customer_id != 0){
        that.xiangqing()
      }
    },
    tianjia(e){
      console.log(e);
      var that = this
      if( e == 1 ){
        that.editVisible7 = true
        that.isInfo = 1
      } else if( e == 3 ){
        that.editVisible7 = true
        that.isInfo = 3
      }
    },
    //内容管理
    Clue(e){
      this.dialogVisib = true
      this.dialoge = e
      if( e == 1 ){
        this.ClueData = this.salestype
        this.AFF = 1
      } 
    },
    CreateAdd(e){
      var that = this
      var srt = {
        content: "新加",
        status: 'normal',
        type: "custom",
        value:0,
      }
      if( e == 1 ){
        var ATT = that.salestype
        ATT.push(srt)
        that.salestype = ATT
      } 
    },
    determine(){
      var that = this
      console.log(that.dialoge);
      if(that.dialoge == 1){
        that.fieldKey = 'sales_business_type'
        that.content = that.salestype 
        that.daohang()
      }
    },
    haeDete(data){
      var that = this 
      let valueId = data.value
      if (data.type == 'default') {
      } else {
        if(that.dialoge == 1){
          var ATT = that.salestype
          let index = ATT.findIndex(obj => obj.value  === valueId)
          ATT.splice(index, 1)
          that.salestype = ATT
        }
      }
    },
    daohang(){  //添加接口
      var that = this 
      axios({
        method: 'post',
        url: '/admin/company/content/edit',
        data: {
          field_key: that.fieldKey, //内容标识
          content: that.content
        },
      }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.dialogVisib = false
            that.staff()
          }
      }) 
    }, 
//-------列表-------------------------------------------------------------------------
    staff(){
      var that = this
      axios.get('/admin/workbench/sales/statisticsColumns',{   // 设置选项 
          params:{
            type: 'patent' 
          }
        }).then(function (res) {
        if (res.data.code == 0) {
          alert('请求失败');  
        } else {
          that.BBBBB_list = res.data.data.list
          that.BBBBB_content = res.data.data.content
          that.salestype = res.data.data.content.sales_business_type
        }
      }) 
      axios.get('/admin/ajax/businessList',{   // 服务商
        }).then(function (res) {
          if (res.data.code == 0) {
            alert('请求失败');  
          } else{ 
            that.BusinessList = res.data.data
          }
      })
      axios.get('/admin/ajax/siteList',{   // 站点列表    
        }).then(function (res) {
          if (res.data.code == 0) {
            alert('请求失败');  
          } else{ 
            that.SiteList = res.data.data
          }
      })
    },
    xiangqing(){
      var that = this
      console.log(that.Row.customer_id);
      axios.get('/admin/customer/contract/read',{   // 合同详情
          params:{
            id: that.editId,
            customer_id: that.Row.customer_id
          }
        }).then(function (res) {
        if (res.data.code == 0) {
          alert('请前往订单管理添加合同');  
        } else { 
            that.editVisible3 = true
            that.Customer = res.data.data.customer
            that.Tongji = res.data.data.tongji
            that.OrderList = res.data.data.orderList
            that.FileList = res.data.data.fileList
        }
      })
    },
    yuangong(){
      var that = this
      axios.get('/admin/ajax/staffList',{   // 通讯录选择
      }).then(function (res) {
        if (res.code == 0) {
          alert('请求失败');  
        } else{ 
          that.StaFFList = res.data.data
        }
      }) 
    },
    workbench(){
      var that = this  
      axios({                                               //附件列表
        method: 'post',
        url: '/admin/workbench/sales_info/fileList', 
        data: {
          type:'patent',
          source_id: that.editId
        },
      }).then(function (res) {
        if (res.data.code == 0) {
          that.$message.warning(res.data.msg);
        }else {
          that.FileList_TEXT = res.data.data.fileList
          that.flowRead()
        }
      })
    },
    TextMoMo(e) {
      var arr = TextMoth.getData(e)
      return arr  
    },
    formatDate(value) {    // 时间戳
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return y + "-" + MM + "-" + d
    },
    formatDate2(value) {    // 时间戳
       let date = new Date(value);
       let y = date.getFullYear();
       let MM = date.getMonth() + 1;
       MM = MM < 10 ? "0" + MM : MM;
       let d = date.getDate();
       d = d < 10 ? "0" + d : d;
       return y
    },
     Close() {  //关闭按钮
      var that = this
      that.ruleFormRef = false;
      that.Order = {}
      that.Row = {}
    },
    //勾选
    Selection(e) {
      var that = this
      console.log(e);
      var arr = []
      var acc = []
      if (e.length == 0) {
        that.multipleSelection_id = null
      } else {
        for (let i = 0; i < e.length; i++) {
          const element = e[i];
          //主键ID
          arr.push(element.id)
          acc.push(element)
        }
        that.multipleSelection_id = arr
      }
      that.multipleSelection = acc
    },
    UP_Success2(e) {  //图片上传成功
      var that = this
      that.Row_Eurl2.push(e.data.url)
    },
    HAndemove2(file, fileList) {
      var that = this
      that.Row_Eurl2 = []
      for (let i = 0; i < fileList.length; i++) {
        const element = fileList[i];
        that.Row_Eurl2.push(element.response.data.url)   
      }
    },
    UP_Success(e) {  //图片上传成功
      var that = this
      that.Row_Eurl.push(e.data.url)
    },
    HAndemove(file, fileList) {
      var that = this
      that.Row_Eurl = []
      for (let i = 0; i < fileList.length; i++) {
        const element = fileList[i];
        that.Row_Eurl.push(element.response.data.url)
      }
    },
    UP_Success4(e, fileList2) {                //附件上传成功
      var that = this
      that.File2 = e.data.url
      that.File2_name = fileList2.name
    },
    Chang_ses(e) {             // 切换我的线索
      var that = this
      that.type = e  
      that.loadTable()
    },
    //导航选择
    handleSelect(key){
      if(key == 1){
        this.isShow = 1
      }else if(key == 2){
        this.isShow = 2
      }
    },
  }
}
</script>

<style>

</style>